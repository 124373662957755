import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from '@ths/design-system';
import { FeedbackReviewVariant } from 'components/FeedbackReviewList/FeedbackReviewList.constants';

export const REVIEW_CATEGORIES = [
    {
        value: 'organised',
        i18n: 'components_CommentCard_review_organised',
    },
    {
        value: 'reliable',
        i18n: 'components_CommentCard_review_reliable',
    },
    {
        value: 'selfSufficient',
        i18n: 'components_CommentCard_review_selfSufficient',
    },
    {
        value: 'tidy',
        i18n: 'components_CommentCard_review_tidy',
    },
    {
        value: 'petCare',
        i18n: 'components_CommentCard_review_petCare',
    },
];

export const FEEDBACK_CATEGORIES = [
    {
        value: 'communication',
        i18n: 'components_CommentCard_review_communication',
    },
    {
        value: 'hospitality',
        i18n: 'components_CommentCard_review_hospitality',
    },
    {
        value: 'cleanliness',
        i18n: 'components_CommentCard_review_cleanliness',
    },
    {
        value: 'petBehaviour',
        i18n: 'components_CommentCard_review_pet_behaviour',
    },
    {
        value: 'accuracyOfListing',
        i18n: 'components_CommentCard_review_accuracy_of_listing',
    },
];

const ReviewRatings = ({ variant, data }) => {
    const { t } = useTranslation();
    const categories =
        variant === FeedbackReviewVariant.REVIEW ? REVIEW_CATEGORIES : FEEDBACK_CATEGORIES;

    return (
        <div className="flex flex-col gap-y-4">
            {categories.map(({ value, i18n }) => {
                const rating = data[value];

                if (!rating) {
                    return null;
                }

                return (
                    <div key={value} className="flex items-center gap-x-4">
                        <Rating value={rating} size="sm" />

                        <span className="text-sm">{t(i18n)}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default ReviewRatings;
